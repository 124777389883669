import React from "react"
import PropTypes from "prop-types"
// Utilities
import kebabCase from "lodash/kebabCase"
// Components
//import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import SEOTAG from '../components/seotag'
import Layout from '../components/Layout'
import { SlugLink } from '../components/utils'
// import { Button } from 'react-bootstrap'
const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title, domainName },
    },
  },
  location
}) => {
  if (location != null) {
    if (location.href != null && location.href.endsWith('/')) {
      window.location.href = window.location.href.substring(0, window.location.href.length - 1);
    }
  }
  return (
    <Layout location={location} title={title}>
      <SEOTAG
        title={`All Tags`}
        description={`All Tags - ${domainName}`}
      // keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <div className="col-lg-8 p1030">
        <article className="sppa">
          <header className="arhd">
            <h1 className="arhl">Tags</h1>
          </header>

          <div className="tdpc tagsc2">
            {group.map((tag, index) => (
              <Link key={index} to={`/tags/${SlugLink(kebabCase(tag.fieldValue))}`}>
                {tag.fieldValue} ({tag.totalCount})
              </Link>
            ))}
          </div>
        </article>

      </div>

    </Layout>
  )
}
TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}
export default TagsPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        domainName
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`